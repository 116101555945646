@charset "utf-8";

.loading-area{
    display:none;
}
.icon-nav-area{
    opacity: 1;
}
.container-mv{
    visibility: visible;
}
.container-mv .row .title-box{
    display: block;
}

.to-top{
    display: none;
}